import React from 'react';
import { Link } from 'gatsby';

import { useLocale } from '../hooks/locale';
import config from '../../config.json';

const LocalizedLink = React.forwardRef(({ to, ...props }, ref) => {
  const { locales } = config;
  const { locale } = useLocale();
  const isIndex = to === '/';
  const path = locales[locale].default
    ? to
    : `/${locales[locale].path}${isIndex ? `` : `${to}`}`;

  return <Link {...props} ref={ref} to={path} />;
});
LocalizedLink.displayName = 'LocalizedLink';

export default LocalizedLink;
