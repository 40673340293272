import React from 'react';

import { LocaleProvider } from '@src/hooks/locale';
import BaseLayout from '@src/layouts/BaseLayout';

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
export default function wrapPageElement({ element, props }) {
  return (
    <LocaleProvider>
      <BaseLayout {...props}>{element}</BaseLayout>
    </LocaleProvider>
  );
}
