import { useLocale } from '../hooks/locale';
import config from '../../config.json';

export default function useSlugs() {
  const { locale } = useLocale();

  const obj = {};

  config.pages.forEach((page) => {
    obj[`${page.id}Slug`] = `/${page.slugs[locale]}`;
  });

  return obj;
}
