import React from 'react';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';

import useTranslations from '@src/components/useTranslations';

export default function NewsletterForm({ ...props }) {
  const {
    newsletterLabel,
    newsletterButton,
    newsletterMessage,
  } = useTranslations();

  const [state, setState] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    window.open('https://buttondown.email/streetmix', 'popupwindow');
    setOpen(true);
  };

  return (
    <>
      {/* See Buttondown template
          https://buttondown.email/share#form */}
      <form
        action="https://buttondown.email/api/emails/embed-subscribe/streetmix"
        method="post"
        target="popupwindow"
        onSubmit={handleSubmit}
        {...props}
      >
        <input type="hidden" name="tag" value="via website" />
        <input type="hidden" name="embed" value="1" />
        <TextField
          fullWidth
          label={newsletterLabel}
          margin="normal"
          name="email"
          onChange={handleChange}
          required
          size="small"
          type="email"
          value={state.email || ''}
          variant="outlined"
        />
        <Button color="secondary" type="submit" variant="contained">
          {newsletterButton}
        </Button>
      </form>
      <Snackbar
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} variant="filled" severity="success">
          {newsletterMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
