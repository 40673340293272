import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import Footer from '@src/components/Footer';
import Navbar from '@src/components/Navbar';
import NoSsr from '@mui/material/NoSsr';
import Topbar from '@src/components/Topbar';
import defaultTheme from '@src/themes/defaultTheme';
import { useLocale } from '@src/hooks/locale';
import GlobalStyles from './GlobalStyles';

export default function BaseLayout({ children, ...props }) {
  // Using the useLocale() hook to define the correct locale
  // that will be available in all components of the tree thought its context
  const { changeLocale } = useLocale();
  changeLocale(props.pageContext.locale);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles />
        <CssBaseline />
        <NoSsr>
          <Topbar {...props} />
          {children}
          <Footer {...props} />
          <Navbar {...props} />
        </NoSsr>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
