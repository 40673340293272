import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    'body, html': {
      overflowX: 'hidden',
    },
    'blockquote, figure': {
      padding: 0,
      margin: 0,
    },
    'ul, li': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '.block': {
      alignContent: 'stretch',
      alignItems: 'stretch',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      padding: theme.spacing(6, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(12, 0),
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(18, 0),
      },
    },
    '.block--2of3': {
      minHeight: `${(100 / 3) * 2}vh`,
    },
    '.block--1of2': {
      minHeight: '50vh',
    },
    '.block--1of3': {
      minHeight: `${100 / 3}vh`,
    },
    '.container': {
      position: 'relative',
      zIndex: 1,
    },
    '.ornament': {
      pointerEvents: 'none',
      position: 'absolute',
      userSelect: 'none',
      zIndex: 1,
    },
    '.ornament img': {
      position: 'relative',
      width: '100%',
    },
    '.hero-title': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(4),
        maxWidth: '80%',
      },
      [theme.breakpoints.up('xl')]: {
        marginBottom: theme.spacing(4),
      },
    },
    '.hero-text': {
      [theme.breakpoints.up('md')]: {
        width: `${(100 / 3) * 2}%`,
      },
    },
    '.hero-button': {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(4),
      },
    },
  },
}));

// This component doesn't render DOM -- it just defines global CSS
// in a child component rendered by the parent <ThemeProvider />.
// See the Mui v4 to v5 migration docs
// https://mui.com/material-ui/migration/migration-v4/#set-up-themeprovider
export default function GlobalStyles() {
  useStyles();

  return null;
}
